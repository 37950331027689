<template>
  <el-row class="el-menu-demo">
    <el-col :md="2">
      <ul role="menubar" class="el-menu--horizontal el-menu">
        <li role="menuitem" class="el-menu-item">
          <a href="/" style="color: #007bff;text-decoration-line: none">
            <img src="@/assets/img/icon/logo.png" class="logo" alt="img">
            tnb
          </a>
        </li>
      </ul>
    </el-col>
    <el-col :md="8">
      <el-menu
        mode="horizontal"
      >
        <el-menu-item index="1">
          <a href="/video" style="text-decoration-line: none">
            <span style="color: #007bff">视频</span>
          </a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="/shortvideo" style="text-decoration-line: none">
            <span style="color: #007bff">短视频</span>
          </a>
        </el-menu-item>
        <el-menu-item index="5">
          <a href="/playlist" style="text-decoration-line: none">
            <span style="color: #007bff">播放列表</span>
          </a>
        </el-menu-item>
      </el-menu>
    </el-col>
    <el-col :md="8">
      <ul class="el-menu--horizontal el-menu">
        <li class="el-menu-item">
        </li>
      </ul>
    </el-col>
    <el-col :md="6">
      <ul class="el-menu--horizontal el-menu">
        <li class="el-menu-item">
        </li>
      </ul>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'LoginBar',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
}

.logo {
  width: 30px;
  position: relative;
}
</style>
