<template>
  <el-container>
    <el-main>
      <login-bar />
      <router-view />
      <el-row class="movie-list">
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px" />
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px" />
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px; text-align: center">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>找回密码</span>
              </div>
              <div class="text item">
                <el-form ref="form" :model="userForgot" label-width="100px">
                  <el-form-item label="邮箱/手机号" label-width="100px">
                    <el-input
                      v-model="userForgot.principal"
                      placeholder="请输入邮箱或手机号"
                      style="width: 45%; padding-right: 10px"
                    />
                    <el-button :disabled="isBtn" @click="forgotVerifyCode">{{ code }}</el-button>
                  </el-form-item>
                  <el-form-item label="验证码" label-width="90px">
                    <el-input
                      v-model="userForgot.verifyCode"
                      placeholder="请输入短信验证码"
                      style="padding-right: 1px"
                    />
                  </el-form-item>
                  <el-form-item label="新密码">
                    <el-input
                      v-model="userForgot.newCredential"
                      type="password"
                      placeholder="请输入新密码"
                      style="padding-right: 1px"
                    />
                  </el-form-item>
                  <el-form-item label="图形验证码" label-width="90px">
                    <el-image :src="captchaCode" @click="getCaptcha" />
                    <el-input
                      v-model="userForgot.captchaCode"
                      placeholder="请输入图形验证码"
                      style="width: 50%; padding-right: 10px"
                    />
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      :loading="isLoading"
                      @click.native="forgotBtn"
                    >找回</el-button>
                    <el-button type="plain" @click="login">返回登录</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import LoginBar from 'components/layout/LoginBar'

export default {
  name: 'Forgot',
  components: {
    LoginBar
  },
  mixins: [userMixin],
  watch: {
    $route() {
      this.$router.go()
    }
  },
  created() {
    document.title = '找回密码'
    this.fetchPubkey(3)
  },
  data() {
    return {
    }
  },
  methods: {
    login() {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
